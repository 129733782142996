(function ($) {

    var tobin = {

        navigation_show: function navigation_show() {

            var $ = jQuery;

            var dropdown_link = $('ul#Header_menu > li.nav-item.dropdown');

            var menu = function(){

                dropdown_link.on('mouseenter', function () {
                    if($(window).width()>990){
                        $(this).addClass('show').find('.dropdown-menu').addClass('show');
                    }
                }).on('mouseleave', function () {
                    if($(window).width()>990){
                        dropdown_link.removeClass('show').find('.dropdown-menu').removeClass('show');
                    }
                });
            };

            menu();

            // on resize window if we are on desktop mode
            // we'll close the menu
            $(window).on('resize', function() {
                setTimeout(function()  {

                    menu();

                }, 250);
            });

        },
        navbar_actions: function navbar_actions() {
            // highlight inner menu items
            $('ul#Header_menu > li').each(function (index, item) {
                // if inside there are active classes
                if($(item).find('.dropdown-item.active').length){
                    $(item).addClass('active');
                }
            });
            // $('ul#Header_menu > li#menu-item-2287').removeClass('active');

        },
        get_latest_news : function(){

            // $.getJSON( "https://manning-financial.ie/?latest-news=get", function( data ) {

                // console.log(data);
                // var slider_content = '';
                // $.each( data, function( key, val ) {
                //
                //     slider_content += '<a target="_blank" class="blog-items__news-item" href="'+val.permalink+'" >\n' +
                //         '                            <h5>'+val.title+'</h5>\n' +
                //         '                            <span class="date">'+val.date+'</span>\n' +
                //         '                            <p>'+val.excerpt+'</p>\n' +
                //         '                            <span class="readmore">Read more &raquo;</span>\n' +
                //         '                        </a>';
                //
                // });


            $('#latest-news-list').slick({
                slidesToShow: 3,
                infinite: true,
                nextArrow: $('.latest-news-btns .slick-prev'),
                prevArrow: $('.latest-news-btns .slick-next'),
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            infinite: true,
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            infinite: true,
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });



            // $('#latest_news_here').slick({
            //         slidesToShow: 4,
            //         infinite: true,
            //         responsive: [
            //             {
            //                 breakpoint: 1024,
            //                 settings: {
            //                     slidesToShow: 3,
            //                     slidesToScroll: 3,
            //                     infinite: true,
            //                 }
            //             },
            //             {
            //                 breakpoint: 600,
            //                 settings: {
            //                     infinite: true,
            //                     slidesToShow: 2,
            //                     slidesToScroll: 2
            //                 }
            //             },
            //             {
            //                 breakpoint: 480,
            //                 settings: {
            //                     infinite: true,
            //                     slidesToShow: 1,
            //                     slidesToScroll: 1
            //                 }
            //             }
            //         ]
            //     });

            // });

        },
        bxslider: function () {

            $('#financial_flyers_here, #newsletters_here, #blog_items_here').slick({
                slidesToShow: 4,
                infinite: true,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            infinite: true,
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            infinite: true,
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            infinite: true,
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });

            $('.testimonials__list').slick({
                dots: true,
                adaptiveHeight: true,
            });

            $('.hero__slider').slick({
                arrows: true,
                fade: true,
                adaptiveHeight: true,
                speed: 300
            });

        },
        tools_hover: function init() {
            // tools
            $('ul.tools__list li a').on('mouseenter', function () {

                var icon = $(this).find('.icon');
                var classNames = icon.attr('class').split(' ');
                var iconActiveClassName = classNames[1]+'-hover';
                var iconInitialClassName = classNames[1];
                icon.removeClass().addClass('icon '+iconActiveClassName);
                icon.data('classname', iconInitialClassName);

                // console.log(icon, classNames);

            }).on('mouseleave', function () {

                var icon = $(this).find('.icon');
                var initialClassName = icon.data('classname');
                icon.removeClass().addClass('icon '+initialClassName);

            });

            // media
            $('.content-page-media .media-list__item').on('mouseenter', function () {

                // $(this).find('img').attr("src", function(index, attr){
                //         return attr.replace(".png", "-active.png");
                // });

                $(this)
                    .find('.icon')
                    .removeClass()
                    .addClass('icon icon-hover-red');

            }).on('mouseleave', function () {

                $(this)
                    .find('.icon')
                    .removeClass()
                    .addClass('icon icon-hover-white');

                // $(this).find('img').attr("src", function(index, attr){
                //     return attr.replace("-active.png", ".png");
                // });

            });

            $('[data-fancybox]').fancybox({
                fitToView : true,
                toolbar  : true,
                smallBtn : true,
                nextSpeed: 0, //important
                prevSpeed: 0, //important
                afterLoad : function( instance, current ) {
                    console.info( instance, current );
                    var maxWidth = '1167px';
                    var maxHeight = '100%';
                    if(current.src=='http://www.mindthepensiongap.ie/riskprofiler/index.html?customer#start'){
                        maxWidth = '720px';
                        maxHeight = '520px';
                    }
                    if(current.src=='http://www.avivaincomeprotection.ie/?type=customer'){
                        maxWidth = '710px';
                        maxHeight = '560px';
                    }
                    $('.fancybox-content').css({
                        'max-width': maxWidth,
                        'max-height': maxHeight,
                    });
                },
                iframe : {
                    preload : true,
                    css: {
                        'max-width' : '1167px'
                    }
                },
                buttons: [
                    "zoom",
                    "share",
                    "slideShow",
                    "fullScreen",
                    "download",
                    "thumbs",
                    "close"
                ],
            });


        },
        cookie_open: function cookie_open(){

            $(document).on('click','[data-open-cookie]',function () {

                document.cookie = 'cookieconsent_status' + '=; Max-Age=0';

                window.cookieconsent.initialise({
                    "status" : 'allow',
                    "palette": {
                        "popup": {
                            "background": "#29263b"
                        },
                        "button": {
                            "background": "#d0203a"
                        }
                    }
                });

                return false;
            });

        },
        scroll_to: function(){

            function scroll(target) {

                // console.log(target);

                // if element exists
                if (target.length) {

                    // declare offset from top value
                    var shift = target.offset().top - 50;

                    // animate body by scrolling
                    $('html, body').animate({
                        scrollTop: shift
                    }, 250, 'swing');
                }
            }

            $(document).on('click','a.nav-link',function (event) {

                // href
                var href = $(this).prop('href');

                // if match
                if(href.match(/#who-we-are/i)!=null){

                    // then scroll
                    scroll($('#who-we-are'));
                    // event.preventDefault();
                }
            });

            // scroll to hash on page load
            var hash = window.location.hash;
            if(hash.match(/#who-we-are/i)!=null){
                scroll($(hash));
            }
        },



        init: function init() {
            // navigation
            this.navigation_show();
            this.navbar_actions();
            this.bxslider();
            this.tools_hover();
            this.cookie_open();
            this.scroll_to();
            this.get_latest_news();
        }
    };

    window.onload = function(){
        tobin.init();
    }



})(jQuery);